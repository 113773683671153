






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IFormParams } from '@/interfaces/IFormParams';

import BackButton from '@/components/ui/BackButton.vue';

interface FAQItem {
  question: string;
  answer: string;
}

interface ExternalLink {
  title: string;
  url: string;
}

@Component({
  components: {
    BackButton,
  },
})
export default class Help extends Vue {
  subject = '';
  message = '';
  email = '';
  followUp = false;
  loading = false;
  feedbackActive = false;

  subjects = {
    account: 'Account / Billing',
    bugs: 'Bugs',
    comments: 'Comments / Concerns',
    question: 'General Question',
    other: 'Other',
  };

  // TODO: get these from DB
  faq: FAQItem[] = [
    {
      question: 'What’s included in my Drumnow Subscription each month?',
      answer:
        'Drumnow subscribers are supplied with 200 Tokens. Each token is equal to 1 Loop or Midi! You can use Tokens to download Drumnow Singles, Production Spices Loops, Factory Pack Loops and Artist Pack Loops. ',
    },
    {
      question: 'Can I buy Packs outright with no membership?',
      answer:
        "Yes, but we strongly encourage you to become a Subscriber to get your money's worth.",
    },
    {
      question:
        'How many New Packs Can I expect monthly When becoming a Drumnow Subscriber?',
      answer: 'Drumnow typically Releases 2-3 New Unique Packs Per Week!',
    },
    {
      question: 'How much is a Drumnow Factory Pack and what is inside?',
      answer:
        'Factory Packs are $9.99 and there are 50 Unique Loops inside the Pack. There are also usually 3-5 Different Tempos with Pre-Processed MP3 Files inside each Pack.  You can also expect the MIDI files to each Unique Loop in the Following formats for your convenience GGD, Slate, Superior/Mix wave & Drumnow Maps.',
    },
    {
      question: "What if I don't know how to work Drum VST'S?",
      answer:
        "Drumnow includes MP3 Files in all of the associated Tempos of Each pack with our Pre-processed Drumkit. It's as easy as Dragging & Dropping into your DAW.",
    },
    {
      question: "What Drum VST's are compatible with Drumnow?",
      answer:
        'Midi Files are compatible with all VST’S. Our packs come integrated with the Following for your convenience GGD, Slate, Superior/Mix wave & Drumnow default Map. If your Midi map is not listed above, you can simply drag each midi note to whatever Key you prefer in your DAW to make it align to your VST of choice! For more information on this topic, subscribe to the Drumnow YouTube channel for in depth Tutorials, & join the Drumnow Community Facebook group for more information.',
    },
    {
      question: 'Do Tokens roll over, or do they restart every month?',
      answer:
        'Tokens do NOT roll over each month. We Encourage you to use all 200 Tokens each month to maximize your Drumnow experience!',
    },
    {
      question: 'Are there refunds?',
      answer: 'Please contact us if you would like to request a refund.',
    },
    {
      question: "How do I Import Maps into different Drum VST'S?",
      answer:
        'Each Drumnow pack comes integrated with GGD, Slate, Superior/Mix wave & Drumnow’s Default Map. It’s as easy as dragging and dropping into your VST midi track. If you need more information on this, please join the Drumnow community Facebook group or Subscribe to the Drumnow YouTube channel for plenty of step by step Tutorials on this process.',
    },
    {
      question: 'Can I use these samples for commercial use?',
      answer: 'Yes, all samples are for personal and commercial use.',
    },
    {
      question: 'Are all samples royalty free?',
      answer: 'Yes, all samples are 100% royalty free',
    },
    {
      question: 'What are the limitations of licensing?',
      answer:
        'You may not use Sounds as standalone sound effects or loops, or to create new samples or compete with Drumnow or our sample providers. You also may not transfer, broadcast, stream, redistribute or use your Sound except as incorporated into a music product. Please see our Terms of use for the full list of restrictions. ',
    },
    {
      question: 'Can I register my creative work for a copyright?',
      answer:
        'We encourage you to explore your options to register a copyright in your work. While we do not prohibit you from doing so, copyright law will dictate whether or not you are eligible for registration',
    },
    {
      question:
        'What do you mean by not being able to use the “likeness” of an artist in my work?',
      answer:
        'Based on our terms of service, you cannot use the name or likeness of creators whose       samples are distributed on Drumnow. Some examples of using the name of likeness of a sample creator would be to include a photo of them when promoting your track, crediting them as a feature on your track, and/or including them as a contributor to the track for splitting royalties.',
    },
    {
      question:
        'Do I need to give Drumnow credit when registering with a PRO (publishing rights organization) like ASCAP or BMI?',
      answer:
        'Drumnow is not a co-owner of your track, so no need to credit Splice when registering with a PRO.',
    },
    {
      question:
        'Can I use samples from Drumnow Sounds in a composition I’m creating for a movie or video game?',
      answer:
        'You can use Drumnow samples in a composition. If you wish for your composition to be included in an audio-visual work, you have that option being the full owner of your composition.',
    },
    {
      question: 'What is included in a free membership?',
      answer:
        'You will receive access to your library, where you will only be allowed to buy full packs. You will gain access to tutorials and walkthroughs/demos',
    },
  ];

  // TODO: Get these from DB
  externalLinks: ExternalLink[] = [
    {
      title: 'Terms of Service',
      url: 'https://storage.googleapis.com/drumnow-apps_public/documents/tos.pdf',
    },
    {
      title: 'Privacy Policy',
      url: 'https://storage.googleapis.com/drumnow-apps_public/documents/privacy_policy.pdf',
    },
    {
      title: 'Subscription Terms',
      url: 'https://storage.googleapis.com/drumnow-apps_public/documents/subscription_tos.pdf',
    },
    {
      title: 'Giveaway Terms and Conditions',
      url: 'https://storage.googleapis.com/drumnow-apps_public/documents/Drumnow_Giveaway_TOS.pdf',
    },
    {
      title: 'Contact Us',
      url: 'mailto:support@drumnow.io',
    },
  ];

  get selectItems() {
    return Object.values(this.subjects);
  }

  get disabled() {
    return !this.message || !this.email || !this.checkIsEmailValid(this.email);
  }

  checkIsEmailValid(email: string) {
    /* eslint-disable */
      const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return re.test(email);
    }

    handleFeedback() {
      this.feedbackActive = !this.feedbackActive;
    }

    submitForm() {
        // call service
        const params: IFormParams = {
            subject: this.subject,
            message: this.message,
            email: this.email,
            followUp: this.followUp
        };

        this.loading = true;

        this.$store.dispatch('submitForm', params)
        .then(() => {
            this.initForm();
        })
        .finally(() => {
            this.loading = false;
        });
    }

    initForm() {
        this.subject = this.subjects.question;
        this.message = '';
        this.followUp = false;
    }

    mounted() {
        this.initForm();
    }
}
